@use "../../util/styles.module.scss" as *;

.wrapper {
  width: 100%;
  background-color: $blue;
  border: none;
  border-radius: 4px;
  padding: 10px 0px;

  @include sm {
    width: fit-content;
    padding: 10px 20px;
  }

  p {
    margin-bottom: 0;
  }
  &:hover {
    background-color: $orange;
    color: $background;

    cursor: pointer;
  }
}

.button {
  color: $text;
  text-decoration: none;
  appearance: button;
}
