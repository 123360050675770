$text: #2c2311;
$background: #fdf2d9;
$orange: #e8802f;
$brown: #514120;
$blue: #9ac2c9;

// Small smartphones
$screen-xs-min: 390px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// min width for screens
$screen-lg-layout-width: 1100px;

// min width for screens
$screen-sm-layout-width: 350px;

// Extra Small devices
@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin rwd($screen) {
  @media (min-width: $screen) {
    @content;
  }
}
