@use "../../util/styles.module.scss" as *;

.h1 {
  font-family: "intro_rustbase", sans-serif;
  font-weight: normal;
  font-size: 18px;
  margin: 10px 0;

  @include md {
    font-size: 20px;
  }
  @include lg {
    font-size: 22px;
  }
}

.h2 {
  font-family: "garetbook", sans-serif;
  font-weight: 100;
  margin: 10px 0;

  font-size: 24px;

  @include md {
    font-size: 28px;
  }

  @include lg {
    font-size: 32px;
  }
}

.p {
  font-family: "garetbook", sans-serif;
  font-weight: 100;
  font-size: 14px;

  @include md {
    font-size: 16px;
  }

  @include lg {
    font-size: 18px;
  }
}

.label {
  font-family: "garetbook", sans-serif;
  letter-spacing: 0.12em;
  font-weight: 100;
  margin: 0px;
  margin-bottom: 8px;
  font-size: 14px;
  text-transform: lowercase;

  @include md {
    font-size: 16px;
  }

  @include lg {
    font-size: 18px;
  }
}

.larger {
  font-size: 18px;

  @include md {
    font-size: 20px;
  }

  @include lg {
    font-size: 22px;
  }
}
