@use "../../util/styles.module.scss" as *;

.socials {
  margin-top: 16px;
  img {
    width: 24px;
    height: 24px;
  }

  @include sm {
    img {
      width: 32px;
      height: 32px;
    }
  }
}

.spacing {
  :first-child {
    margin-right: 16px;
  }
}

.inverted {
  color: $background;
}
