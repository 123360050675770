@use "../../../util/styles.module.scss" as *;

.footer {
  background-color: $orange;
  color: $background;
  width: 100%;
  margin-top: 10%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;

  @include sm {
    flex-direction: row;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

.logo {
  width: 120px;

  @include sm {
    width: 164px;
  }
}

.content {
  margin-top: 32px;
  text-align: left;

  @include sm {
    text-align: right;
  }
  p {
    margin: 0;
  }
}
