@use "../../../util/styles.module.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  margin-top: 15%;

  @include md {
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }
}

.photoContainer {
  width: 250px;
  height: 250px;
  background-color: $brown;
  margin-bottom: 24px;

  @include md {
    width: 400px;
    height: 400px;
    margin-right: 24px;
    margin-bottom: 0;
  }
}

.photo {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.content {
  width: 100%;
  text-align: left;
  white-space: pre-wrap;

  @include md {
    width: 50%;
  }
}

.heading {
  margin-bottom: 32px;

  @include sm {
    margin-bottom: 64px;
  }
}

.cta {
  margin-top: 16px;

  @include sm {
    margin-top: 24px;
  }
}
