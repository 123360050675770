@use "../../util/styles.module.scss" as *;

.container {
  overflow-x: auto;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 4px;
  width: $screen-sm-layout-width;

  @include md {
    margin-top: 16px;
    margin-bottom: 8px;
    width: $screen-lg-layout-width;
  }
}

.photoContainer {
  min-width: 152px;
  height: 152px;
  margin-right: 16px;
  background-color: $brown;

  @include md {
    min-width: 200px;
    height: 200px;
    margin: 0;
  }
}

.photo {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.additionalMargin {
  margin-right: 16px;
}
