@use "../../util/styles.module.scss" as *;

$min-height: 42px;
$max-height: 126px;
$min-height-mobile: 29px;
$max-height-mobile: 94px;

$icon-width: 24px;
$icon-height: 24px;
$icon-width-mobile: 18px;
$icon-height-mobile: 18px;

@mixin InvertStyles {
  background-color: $text;
  color: $background;
}

.position {
  display: block;
  position: absolute;
  top: 28px;
  width: 100%;
  right: 0;

  @include md {
    top: 32px;
  }

  @include lg {
    top: 50px;
  }
}

.container {
  background-color: $background;
  border: 1px solid $text;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;

  // Set height for the transition animation
  height: $min-height-mobile;
  transition: height 300ms;

  font-family: "intro_rustbase", sans-serif;
  font-weight: 100;
  font-size: 18px;
  text-transform: uppercase;

  @include lg {
    height: $min-height;
    font-size: 22px;
  }

  &.isOpen {
    height: $max-height-mobile;

    @include lg {
      height: $max-height;
    }
    // Invert styling
    .selectedLocale {
      cursor: pointer;
      @include InvertStyles;
      .worldIcon {
        background-image: url("../../assets/world_background.svg");
      }
    }
  }
}

.icon {
  min-width: $icon-width-mobile;
  height: $icon-height-mobile;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: contain;

  @include lg {
    min-width: $icon-width;
    height: $icon-height;
  }
}

.worldIcon {
  background-image: url("../../assets/world.svg");
}

.selectedIcon {
  min-width: 16px;
  height: 16px;
  background-image: url("../../assets/check.svg");
  background-repeat: no-repeat;
  background-position: center;

  @include lg {
    min-width: 20px;
    height: 20px;
  }
}

.locale {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;

  @include lg {
    width: 60px;
  }
}

.selectedLocale {
  padding: 3px 20px;

  @include lg {
    padding: 6.5px 24px;
  }

  &:hover {
    cursor: pointer;
    @include InvertStyles;

    .worldIcon {
      background-image: url("../../assets/world_background.svg");
    }
  }
}

.localeOptionContainer {
  height: 0;
  overflow: hidden;

  &.isOpen {
    height: $max-height;
  }
}

.localeOption {
  padding: 4px 20px;

  @include lg {
    padding: 8px 24px;
  }

  &:hover {
    cursor: pointer;
    @include InvertStyles;

    .selectedIcon {
      background-image: url("../../assets/check_background.svg");
    }
  }
}
