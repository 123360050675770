@use "../../../util/styles.module.scss" as *;

$width: 100%;
$radius: calc($width / 2);
$margin: calc($radius / 3) * 2;

$xxs-mobile-radius: calc($width / 5);
$xs-mobile-radius: calc($width / 4);
$mid-xs-mobile-radius: calc($width / 3);
$sm-mobile-radius: calc($width / 2.5);

.container {
  background-color: $brown;
  color: $background;

  width: $width;
  border-top-left-radius: $radius $xxs-mobile-radius; // 50% 20%
  border-top-right-radius: $radius $xxs-mobile-radius;

  @include xs {
    border-top-left-radius: $radius $xs-mobile-radius; // 50% 25%
    border-top-right-radius: $radius $xs-mobile-radius;
  }

  @include rwd(500px) {
    border-top-left-radius: $radius $mid-xs-mobile-radius; // 50% 33%
    border-top-right-radius: $radius $mid-xs-mobile-radius;
  }

  @include sm {
    border-top-left-radius: $radius $sm-mobile-radius; // 50% 40%
    border-top-right-radius: $radius $sm-mobile-radius;
  }

  @include md {
    border-top-left-radius: $radius $radius; // 50% 50%
    border-top-right-radius: $radius $radius;
  }
}

.content {
  text-align: left;
  padding: $radius 44px 44px;
  white-space: pre-wrap;

  @include rwd(900px) {
    padding: $margin $margin 115px 200px;
  }
}

.heading {
  margin-bottom: 75px;
}

.gallery {
  width: 100%;
  display: flex;
  overflow: auto;
}
