@font-face {
  font-family: "garetbook";
  src: url("./fonts/garet-book-webfont.woff2") format("woff2"),
    url("./fonts/garet-book-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "intro_rustbase";
  src: url("./fonts/introrust-base-webfont.woff2") format("woff2"),
    url("./fonts/introrust-base-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
