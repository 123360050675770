@use "../../../util/styles.module.scss" as *;

.logo {
  width: 252px;
  margin: auto;
  margin-bottom: 56px;

  @include md {
    width: 326px;
  }

  @include lg {
    width: 400px;
    margin-bottom: 64px;
  }

  img {
    width: 100%;
  }
}

.container {
  text-align: center;
  margin: 80px auto;

  @include md {
    margin: 252px auto 124px auto;
  }

  @include lg {
    margin: 252px auto 100px auto;
  }
}

.button {
  margin: auto;
  margin-top: 48px;
}

.subText {
  @include md {
    margin: 0 10%;
  }

  @include lg {
    margin: 0 20%;
  }
}
