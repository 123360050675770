@use "../../util/styles.module.scss" as *;

.wrapper {
  width: 100%;
}

.margin {
  margin: 0 16px;

  // 576px window width and more
  @include sm {
    margin: 0 20px;
  }
  // 992px window width and more
  @include lg {
    margin-left: auto;
    margin-right: auto;
    max-width: $screen-lg-layout-width;
  }
}

.reverse {
  display: flex;
  flex-direction: row-reverse;
}
