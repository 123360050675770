@use "../../util/styles.module.scss" as *;

.beans {
  overflow-x: hidden;
  margin-top: 8px;

  img {
    width: 100%;
  }
}
