@use "../util/styles.module.scss" as styles;

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body {
  background: styles.$background;
  color: styles.$text;
  scrollbar-gutter: stable;
}

* {
  margin: 0;
}
