@use "../../../util/styles.module.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  flex-direction: column-reverse;

  margin-top: 15%;

  @include md {
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
  }
}

.mapContainer {
  width: 100%;
  height: 400px;
  margin-top: 24px;

  @include md {
    width: 400px;
    margin-left: 24px;
    margin-top: 0;
  }
}

.content {
  text-align: left;
  white-space: pre-wrap;
}

.heading {
  margin-bottom: 32px;

  @include sm {
    margin-bottom: 64px;
  }
}

.cta {
  margin-top: 16px;

  @include sm {
    margin-top: 24px;
  }
}
